

import * as conf from './conf';

window.conf = conf;


import './router';



import './components/alert';
import './components/api';
import './components/app';
import './components/axios';
import './components/bet-filter';
import './components/bet_summary';
import './components/dashboard';
import './components/data'
import './components/dialog';
import './components/filter-form';
import './components/filter_by';
import './components/form';
import './components/fuzzy';
import './components/fuzzy-date-range';
import './components/fuzzy-summary';
import './components/ggr';
import './components/header';
import './components/historyfilter';
import './components/login-container';
import './components/logsfilter';
import './components/modal';
import './components/paginate';
import './components/radio';
import './components/select';
import './components/sidebar';
import './components/socket';
import './components/spinner';
import './components/table';
import './components/tabs';
import './components/token';
import './components/toolbar';
import './components/upload';
import './components/upload-file';
// import './components/users';
